import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import iCalendarPlugin from '@fullcalendar/icalendar';
import allLocales from '@fullcalendar/core/locales-all';
import moment from 'moment';
import './index.css';

document.addEventListener('DOMContentLoaded', function() {
  var calendarEl = document.getElementById('calendar');
  let bookDates = [];
  let bookDatesClick = [];
  // let isOverlap = false;
  let busyDays = [];

  function getBeautyDate(date, lang = 'en') {
    let format = 'en-us';
    switch (lang) {
      case 'en':
        format = 'en-us';
        break;
      case 'ru':
        format = 'ru-ru';
        break;

      default:
        format = 'en-us';
        break;
    }
    // console.log(format);
    return date.toLocaleString(format, {month:'short', day:'numeric'})
  }

  const urlParams = new URLSearchParams(window.location.search);

  let curLang = (urlParams.get('lang')) ? urlParams.get('lang') : 'en';

  const supportLangs = ['en', 'ru'];

  if (!supportLangs.includes(curLang)) curLang = 'en';

  const words = {
    book: {
      en: 'Book',
      ru: 'Бронь'
    },
    invalid: {
      en: 'Selected dates are not available!<br/>Choose green dates only interval.',
      ru: 'Выбранные даты недоступны!<br/>Выберите даты в зеленом интервале.'
    },
    future: {
      en: 'Only future dates!',
      ru: 'Только будущие даты!'
    },
    free: {
      en: 'Free dates only!',
      ru: 'Только свободные даты!'
    },
    send: {
      en: 'Send request!',
      ru: 'Отправить запрос!'
    },
    name: {
      en: 'Your name',
      ru: 'Ваше имя'
    },
    phone: {
      en: 'Contact phone',
      ru: 'Ваш телефон'
    },
    success: {
      en: 'Success!',
      ru: 'Успешно!'
    }
  }

  document.querySelector('#err-text').innerHTML  = words.invalid[curLang];
  document.querySelector('#send-request').innerHTML  = words.send[curLang];
  document.querySelector('#input-name').placeholder  = words.name[curLang];
  document.querySelector('#input-phone').placeholder  = words.phone[curLang];
  document.querySelector('#input-house').value  = urlParams.get('id');
  document.querySelector('#success').placeholder  = words.success[curLang];

  var calendar = new Calendar(calendarEl, {
    selectable: true,
    initialView: 'dayGridMonth',
    unselectAuto: false,
    locales: allLocales,
    locale:  curLang,
    // selectOverlap: false,
    plugins: [dayGridPlugin, iCalendarPlugin, interactionPlugin],
    events: {
      //url: 'https://www.airbnb.com/calendar/ical/26589721.ics?s=643a0be08c876eabfa61fc67de8376da',
      url: 'calendars/'+urlParams.get('id')+'.ical',
      format: 'ics',
      display: 'background',
      backgroundColor: '#dadada',
      opacity: 1
    },
    customButtons: {
      bookButton: {
        text: words.book[curLang],
        click: function() {
          if (bookDates.length) {
            document.querySelector('#modal-send').classList.toggle('modal_active');
            document.querySelector('.modal__book-dates').value = getBeautyDate(bookDates[0], curLang) + ' - ' + getBeautyDate(bookDates[1], curLang);
            // console.log(document.querySelector('.modal__book-dates').value);
          }
          else {
            document.querySelector('#modal-error').classList.toggle('modal_active');
          }
        }
      }
    },
    // backgroundColor: 'cyan',
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'bookButton prev,next'
    },
    select: function(info) {
      if (info.start < new Date()) {
        calendar.el.querySelector('.fc-bookButton-button').setAttribute('disabled', 'disabled');
        calendar.el.querySelector('.fc-bookButton-button').innerText = words.future[curLang];
        document.querySelector('#modal-error').classList.add('modal_active');
        calendar.unselect();
      }
      else {
        // console.log('select');
        bookDates[0] = info.start;
        bookDates[1] = info.end;
        bookDates[1] = new Date(bookDates[1].setDate(bookDates[1].getDate() - 1));

        calendar.el.querySelector('.fc-bookButton-button').removeAttribute('disabled');

        if (bookDates[0].getTime() == bookDates[1].getTime()) {
          calendar.el.querySelector('.fc-bookButton-button').innerText = words.book[curLang] + ' ' + getBeautyDate(bookDates[0], curLang);

          let isBusy = false;

          busyDays.forEach(item => {
            let formattedEndDate = new Date(new Date(item.end).setDate(new Date(item.end).getDate() - 1)).toDateString();
            if ((new Date(new Date(item.start).toDateString()).getTime() <= new Date(bookDates[0].toDateString()).getTime()) &&
                (new Date(formattedEndDate).getTime() >= new Date(bookDates[0].toDateString()).getTime())) {
              isBusy = true;
            }
          })

          if (isBusy) {
            calendar.el.querySelector('.fc-bookButton-button').setAttribute('disabled', 'disabled');
            calendar.el.querySelector('.fc-bookButton-button').innerText = words.free[curLang];
            document.querySelector('#modal-error').classList.add('modal_active');
            bookDates = [];
            bookDatesClick = [];
            calendar.unselect();
          }
        }
        else {
          calendar.el.querySelector('.fc-bookButton-button').innerText = words.book[curLang]+ ' ' + getBeautyDate(bookDates[0], curLang) + ' - ' + getBeautyDate(bookDates[1], curLang);

          let isBusy = false;

          busyDays.forEach(item => {
            let formattedEndDate = new Date(new Date(item.end).setDate(new Date(item.end).getDate() - 1)).toDateString();
            if ((new Date(new Date(item.start).toDateString()).getTime() <= new Date(bookDates[1].toDateString()).getTime()) &&
                (new Date(formattedEndDate).getTime() >= new Date(bookDates[0].toDateString()).getTime())) {
              isBusy = true;
            }
          })

          if (isBusy) {
            calendar.el.querySelector('.fc-bookButton-button').setAttribute('disabled', 'disabled');
            calendar.el.querySelector('.fc-bookButton-button').innerText = words.free[curLang];
            document.querySelector('#modal-error').classList.add('modal_active');
            bookDates = [];
            bookDatesClick = [];
            calendar.unselect();
          }
        }
      }
    },
    dateClick: function(info) {
      // console.log(calendar);
      // если день занят, то обработку клика пропустить
      // if (info.dayEl.querySelector('.fc-event')) return;


      if (!bookDatesClick[1]) {
        if (!bookDatesClick[0]) {
          // Есди задана одна дата
          bookDatesClick[0] = info.date;
        }
        else {
          // Если задано 2 даты
          bookDatesClick[1] = info.date;

          // Сортировка. Важно!
          if (bookDatesClick[0].getTime() > bookDatesClick[1].getTime()) {
            bookDatesClick[2] = bookDatesClick[0];
            bookDatesClick[0] = bookDatesClick[1];
            bookDatesClick[1] = bookDatesClick[2];
            bookDatesClick[2] = null;
            bookDatesClick.splice(2, 1);
          }
        }
      }
      else {
        // если уже заданы даты. то делаем на 3 клик новый промежуток
        bookDatesClick = [];
        bookDatesClick[0] = info.date;
      }

      calendar.select( bookDatesClick[0], bookDatesClick[1]?new Date(new Date(bookDatesClick[1]).setDate(bookDatesClick[1].getDate() + 1)):null )
      // calendar.selectOverlap();
    },

    // selectOverlap: function(event) {
    //   console.log('overlapped');
    //   isOverlap = true;
    //   bookDatesClick = [];
    //   calendar.el.querySelector('.fc-bookButton-button').setAttribute('disabled', 'disabled');
    //   calendar.el.querySelector('.fc-bookButton-button').innerText = "Free dates only!";
    //   // return true;
    // },
    eventSourceSuccess: function(content, response) {

      let events = [];
      content.map( function(eventData) {
          var start = moment(eventData.start).startOf('day');
          var end = moment(eventData.end).startOf('day');
          while (start.isSameOrBefore(end)) {
            events.push({
              title: eventData.title,
              start: start.format('YYYY-MM-DD'),
              end: start.add(1, 'day').format('YYYY-MM-DD'),
              extendedProps: {location: null, organizer: null, description: 'Reservation'},
              url: ''
            });
          }
      })

      busyDays = events;
      // Важно!!!! Последняя дата в записях всегда на след день стоит. и она не выделяется в календаре
      return events;
    },
    unselect: function(jsEvent, view) {
      bookDates = [];
    }
  });

  calendar.render();


  document.addEventListener('click', function(e) {
    if (e.target.classList.contains('modal__overlay')) {
      document.querySelectorAll('.modal').forEach(item => {
        item.classList.remove('modal_active');
      })
    }
  });

  document.querySelector('#request-form').addEventListener('submit', function(e) {
    e.preventDefault();

    const form = document.getElementById( "request-form" );
    const xhr = new XMLHttpRequest;
    const FD = new FormData( form );

    xhr.open('POST', '/send.php', true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    var object = {};
    FD.forEach((value, key) => object[key] = value);
    var json = JSON.stringify(object);

    xhr.send(json);

    xhr.onreadystatechange = function() {
      if (xhr.readyState != 4) return;


      if (xhr.status != 200) {
        // обработать ошибку
        console.log(xhr.status + ': ' + xhr.statusText);

        document.querySelector('#modal-send').classList.remove('modal_active');
        document.querySelector('#modal-error').classList.add('modal_active');
      } else {
        document.querySelector('#modal-send').classList.remove('modal_active');
        document.querySelector('#modal-success').classList.add('modal_active');
      }
    }

  });

  document.querySelectorAll('.close-modals').forEach(item => {
    item.addEventListener('click', function(e) {
      e.preventDefault();

      document.querySelectorAll('.modal').forEach(item => {
        item.classList.remove('modal_active');
      })
    });
  })

});
